import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import Entry from './views/entry/entry'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
class App extends Component {
  componentDidMount() {
    this.checkRoute();
  }

  componentDidUpdate() {
    this.checkRoute();
  }

  checkRoute() {
    const currentPath = window.location.href;
    if (!currentPath.includes('/entry/')) {
      var username = window.prompt("Kérem, adja meg a felhasználónevet:");
      var password = window.prompt("Kérem, adja meg a jelszót:");

      if (username === "csocso.eu" && password === "fxNRFMvS5ypD") {
        alert("Sikeres autentikáció!");
      } else {
        window.location.href = "https://www.google.com";
        alert("Autentikáció sikertelen!");
      }
    }
  }
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/entry/:id" name="Nevezés" element={<Entry />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
